<template>

    <svg :id="'chart_' + index" height="auto" width="100%"></svg>
  
</template>

<script>
import * as d3 from 'd3'

export default {
    name: 'D3LineChart',
    props: {
        index: { type: String, default: '1' },
        chartTitle: String,
        lableX: String,
        lableY: String,
        type: String,
        height: {type: Number, default: 720},
        width: { type: Number, default: 960},
        value: {type: Array }
    },
    data () {
    return {
      chart: null,
      line: null,
      points: null,
      margin: { top: 30, right: 30, bottom: 50, left: 30 },
      data: null
    }
    },
    mounted () {
        this.lineChart()
    },
    watch: {
        value () {
            this.lineChart(true)
        }
    },
    methods: {
        initBox () {
            this.chart = d3.select(`#chart_${this.index}`)
                .attr('viewBox', [0, 0, this.width, this.height])
                .append('g')
                .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
        },
        title (posX, posY) {
            // TITEL
            d3.select(`#chartTitle_${this.index}`).remove()

            this.chart
            .append('text')
            .attr('id', `chartTitle_${this.index}`)
            .attr('x', posX)
            .attr('y', posY)
            .attr('text-anchor', 'middle')
            .style('font-size', '1em')
            .style('text-decoration', 'underline')
            .text(this.chartTitle)
        },
        Xlabel (height, width) {
            d3.select(`#xlabel_${this.index}`).remove()

            this.chart.append('text')
                .attr('id', `xlabel_${this.index}`)
                .attr('y', height + 40)
                .attr('x', (width / 2))
                .style('text-anchor', 'middle')
                .text(this.lableX)
            },
        ylable (height) {
            d3.select(`#ylabel_${this.index}`).remove()

            this.chart.append('text')
                .attr('id', `ylabel_${this.index}`)
                .attr('transform', 'rotate(-90)')
                .attr('y', -15)
                .attr('x', -(height / 2))
                .style('text-anchor', 'middle')
                .text(this.lableY)
        },

        lineChart (isUpdate = false) {
            let width = this.width - this.margin.left - this.margin.right
            let height = this.height - this.margin.top - this.margin.bottom

            if (!isUpdate) this.initBox()

            this.title(width / 2, - this.margin.top / 2)
            if (this.lableX) this.Xlabel(height, width)
            if (this.lableY) this.ylable(height)
            if (this.value.length === 0) return

            let achsen = new initXY_Axis(this.chart, this.value, width, height, this.margin)

            // x-Achse
            this.chart.selectAll('g.x.axis').call(achsen.xAxis)
            // y-Achse
            this.chart.selectAll('g.y.axis').call(achsen.yAxis)

            let drawChart = (data) => {
                // Add the line
                let line = d3.line()
                    .defined(d => !isNaN(d.HEIGHT))
                    .x(d => achsen.x(d.date))
                    .y(d => achsen.y(d.HEIGHT))

                let graph = this.chart.selectAll('.graph')
                    .data([data])
                    .attr('d', line)

                graph.enter()
                    .append('path')
                    .attr('class', 'graph')
                    .attr('d', line)
                    .attr('fill', 'none')
                    .attr('stroke', 'steelblue')
                    .attr('stroke-width', 2)
                    .attr('stroke-linejoin', 'round')
                    .attr('stroke-linecap', 'round')

                graph.exit().remove()


                /* this.chart.append("path")
                    .datum(data)
                    .attr("fill", "none")
                    .attr("stroke", "steelblue")
                    .attr("stroke-width", 1.5)
                    .attr("d", d3.line()
                        .x(function(d) { return x(d.date) })
                        .y(function(d) { return y(d.HEIGHT) })
                    ) */
            }
            
            drawChart(this.value)
        }
    }
}

function callAxis (chart, xAxis, yAxis, height) {
  // x-Achse
  chart.append('g')
    .attr('class', 'x axis')
    .attr('transform', 'translate (0, ' + height + ')')
    .call(xAxis)

  // y-Achse
  chart.append('g')
    .attr('class', 'y axis')
    .call(yAxis)
}

function initXY_Axis (chart, data, width, height, margin) {
  this.x = d3.scaleLinear()
    .domain([d3.min(data, d => d.date), d3.max(data, d => d.date)]).nice()
    .range([margin.left, width])

  this.y = d3.scaleLinear()
    .domain([d3.min(data, d => d.HEIGHT), d3.max(data, d => d.HEIGHT)]).nice()
    .range([height - margin.bottom, margin.top])

  this.xAxis = g => g
    .attr('transform', `translate(0,${height - margin.bottom})`)
    .transition().duration(1000)
    .call(d3.axisBottom(this.x).ticks(width / 80).tickSizeOuter(0))

  this.yAxis = g => g
    .attr('transform', `translate(${margin.left},0)`)
    .transition().duration(1000)
    .call(d3.axisLeft(this.y))

  callAxis(chart, this.xAxis, this.yAxis, height)
}
</script>

<style>

</style>