<template>
    <div>
        <h2>Variable Parameters:</h2>

        <b-form-group        
            label="Satellite Name:"
            label-for="name"
            label-cols-sm="5"
            label-align="right"
            size="sm"
        >
            <b-form-input size="sm" id="name" v-model="parameters.satName" placeholder="Enter satellite name"></b-form-input>
        
        </b-form-group>
        <b-form-group        
            label="Satellite Mass [kg]:"
            label-for="mass"
            label-cols-sm="5"
            label-align="right"
        >
            <b-form-input size="sm" id="mass" v-model="parameters.satMass" placeholder="Enter satellite mass"></b-form-input>
        
        </b-form-group>

        <b-form-group        
            label="Satellite Area [m²]:"
            label-for="satArea"
            label-cols-sm="5"
            label-align="right"
        >
            <b-form-input size="sm" id="satArea" v-model="parameters.satArea" placeholder="Enter satellite area"></b-form-input>
        
        </b-form-group>

        <b-form-group        
            label="Start height [km]:"
            label-for="height"
            label-cols-sm="5"
            label-align="right"
            description="180km - 500 km"
        >
            <b-form-input size="sm" id="height" v-model="parameters.H" placeholder="Enter satellite height"></b-form-input>
        
        </b-form-group>

        <b-form-group        
            label="Solar Flux [SFU]:"
            label-for="flux"
            label-cols-sm="5"
            label-align="right"
        >
            <b-form-input size="sm" id="flux" v-model="parameters.flux" placeholder="Enter solar flux"></b-form-input>
        
        </b-form-group>

        <b-form-group        
            label="Geomagnetic A index:"
            label-for="index"
            label-cols-sm="5"
            label-align="right"
        >
            <b-form-input size="sm" id="index" v-model="parameters.Ap" placeholder="Enter  Geomagnetic A index"></b-form-input>
        
        </b-form-group>
    </div>  
</template>

<script>
export default {
    name: 'parameters',
    data() {
        return {
            
            parameters: { satName: 'TestObj001', satMass: 100, satArea: 1, H: 300, flux: 70, Ap: 0}

        }
    },
    watch: {

        parameters: {
            deep: true,

            handler: function () {

                this.$emit('p_changed', this.parameters)

            }        
        },
        
    }

}
</script>

<style>

</style>