<template>
  <div id="app" class="container">
    <b-jumbotron header="Satellite Orbital Decay" bg-variant="warning" text-variant="white" border-variant="dark"></b-jumbotron>
    <!-- <h1>Satellite Orbital Decay</h1> -->
    <b-row>      
      <b-col>
        <Parameters @p_changed="setParameters"></Parameters>
      </b-col>
      <b-col >
        <h2 class="text-right">Constant Parameters</h2>

          <ul class="text-right mb-5">
            <li>Earth Radius: <span class="listLable"> {{Re}}</span><span class="listUnit"> m</span></li>
            <li>Earth Mass: <span class="listLable"> {{Me}} </span><span class="listUnit"> kg</span></li>
            <li>Gravitational Constant: <span class="listLable"> {{G}}</span><span class="listUnit"> m³/(kg s²)</span></li>
            <li>π: <span class="listLable"> {{pie}}</span><span class="listUnit"></span></li>
          </ul>
        
        <!-- {{parameters}} -->
        <b-button @click="calculate()" variant="outline-dark" style="position: absolute; bottom: 10px; right: 10px;">Calculate</b-button>
      </b-col>
    </b-row>

    <!-- <p>Balistic coefficent: </p> -->
    
    <b-table striped hover small responsive :items="tabelle" :fields="fields"></b-table>

    <p v-if="tabelle.length > 1" class="mb-2">
      Reentry after {{ tabelle[tabelle.length-1].TIME }} days ({{ (tabelle[tabelle.length-1].TIME / 365).toFixed(2)}} years).
    </p>
      

    <D3LineChart :value="chart_data" :chartTitle="parameters.satName" lableX="days" lableY="height [km]"></D3LineChart>

    <p class="mb-0 mt-2"> <i>Reference:</i> </p>
    <small class="text-muted mb-2">For formulas see
      <a href="https://www.sws.bom.gov.au/Category/Educational/Space%20Weather/Space%20Weather%20Effects/SatelliteOrbitalDecayCalculations.pdf"> "Satellite Orbital Decay Calculations.PDF" </a>
    </small>

    
  </div>
</template>

<script>
import Parameters from './components/Parameter.vue'
import D3LineChart from './components/D3LineChart.vue'
// import { D3LineChart } from 'vue-d3-charts';

function pris(val, pr){
    return Number(val.toFixed(pr))
}

export default {
  name: 'App',
  components: {
    D3LineChart,
    Parameters
  },
  data() {
        return {

            chart_data: [],
            chart_config: {
              values: 'HEIGHT',
              date: {
                key: 'date',
                inputFormat: "%Y-%m-%d",
                outputFormat: "%Y-%m-%d",
              },
              axis: {
                yTitle: 'height',
                xTitle: 'days',
                yFormat: ".0f",
                xFormat: "%Y-%m-%d",
                yTicks: 5,
                xTicks: 3
              },
              
            },
                
            parameters: { satName: 'TestObj001', satMass: 100, satArea: 1, H: 300, flux: 70, Ap: 0},

            Re:  6378137,      // Erdradius
            Me:  5.9722E24,    // Erdmasse
            G:   6.6743E-11,   //6.6743E-11   // Gravitationskonstante m³/(kg s²)
            GMe: 3.986004418E14,
            pie: Math.PI,

            //Berechnungseinstellungen
            T: 0,              // Starttag
            dT: 0.1,           // delta Tag
            H1: 10,            // Höhenveränderung [km]
            //

            fields: [ 
              { key:'TIME', label:'TIME [days]' },
              { key:'HEIGHT', label:'HEIGHT [km]' },
              { key:'PERIOD', label:'PERIOD [min]' },
              { key:'MEAN_MOTION', label:'MEAN MOTION [rev / day]' },
              { key:'DECAY', label:'DECAY [rev / day²]'} ],
            tabelle: []
        }
    },
  methods:{
    setParameters(data) {

      this.parameters = data

    },
    calculate () {
      this.chart_data = []
      this.tabelle = []

      let date = new Date(Date.now())

      let satMass = Number(this.parameters.satMass)
      let satArea = Number(this.parameters.satArea)
      let H = Number(this.parameters.H)
      let flux = Number(this.parameters.flux)
      let Ap = Number(this.parameters.Ap)

      let Re  = this.Re
      let GMe = this.GMe
      let pie = this.pie
      let T   = Number(this.T)
      let dT  = Number(this.dT)
      let H1  = Number(this.H1)

      let H2 = H
      let D9 = dT * 24 * 3600                                    // zeitinkrement zu sekunden
      let R  = Re + H * 1000                                     // Orbithöhe
      let P  = 2 * pie * Math.sqrt(Math.pow(R, 3) / GMe)         // Periode in sekunde

      // start loop
      do {
          let SH = (900 + 2.5 * (flux - 70) + 1.5 * Ap) / (27 - 0.012 * (H - 200))
          let DN = 6E-10 * Math.exp(-(H - 175) / SH)            // Atmosperic density
          let dP = 3 * pie * satArea / satMass * R * DN * D9    // decrement in orbital period

          if( H <= H2 ){   
            var Pm  = P / 60
            var MM  = 1440 / Pm
            // var nMM = 1440 / ((P - dP)) / 60

            var decay = dP / dT / P * MM              
              
            this.tabelle.push({TIME: pris(T, 2), HEIGHT: pris(H, 3), PERIOD: pris(Pm, 3), MEAN_MOTION: pris(MM, 5), DECAY: pris(decay, 5)})
            this.chart_data.push({HEIGHT: pris(H, 3), date: pris(T, 2) })
            // this.chart_data.push({HEIGHT: pris(H, 3), date: date.toISOString().split('T')[0] })

            date.setDate(date.getDate() + pris(T, 2));

            H2 = H2 - H1
          }

          P = P - dP
          T = T + dT
          R = Math.pow((GMe * P * P / 4 / pie / pie), 1/3)
          H = (R - Re) / 1000
      } while (H > 180)

      this.tabelle.push({TIME: pris(T, 2), HEIGHT: pris(H, 3), PERIOD: pris(Pm, 3), MEAN_MOTION: pris(MM, 5), DECAY: pris(decay, 5)})
      this.chart_data.push({HEIGHT: pris(H, 3), date: pris(T, 2) })

      // console.table(this.tabelle)
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
  color: #2c3e50;
  margin-top: 40px;
}

ul {
  list-style-type: none;
}

.listLable{
  width: 180px;
  display: inline-block;
  text-align: left;
}

.listUnit{
  width: 75px;
  display: inline-block;
  text-align: right;
}
</style>
